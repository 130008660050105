import React from 'react';
import Button from './Button';
import { useNavigation } from '@react-navigation/core';

const RegisterPrevButton = () => {
const navigation = useNavigation();

return (
    <Button
      variant="outline"
      py="4"
      onPress={() => navigation.goBack()}
      flex={1}
      mr={2}
      >
      Previous
    </Button>
  );
};

export default RegisterPrevButton;
