import React, {useState} from 'react';
import {ScrollView, Image, View, useWindowDimensions, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Center from '../../../components/Center';
import {IMAGE_OPTIONS} from '../../../constants';
import {styles as globalStyles} from '../../../styles';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/reducers';
import {RegistrationData} from '../types';
import {setRegisterData} from '../registerReducer';
import isWeb from '../../../utils/isWeb';
import ScreenWrapper from '../components/ScreenWrapper';
import WebcamCapture from '../components/WebcamCapture';
import * as Sentry from '@sentry/react-native';
import { handlePermissionError } from '../../../utils';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { checkProfileImageValidity } from '../api';
import { showErrorMessage } from '../../../service/flashMessage';

const PhotoCapture = ({navigation}) => {
  const dispatch = useDispatch();
  const {profilePicture: initialProfilePicture} = useSelector(
    (state: RootState) => state?.register,
  );
  const [profilePicture, setProfilePicture] = useState(initialProfilePicture);
  const {width} = useWindowDimensions();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog =()=> setDialogOpen(false);

  const [loading, setLoading] = useState(false);
  const [photoValid, setPhotoValid] = useState(true);

  const handleImageSubmit = () => {
    const registrationData: RegistrationData = {
      profilePicture,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Password');
  };

  const showGalleryPicker = () => {
    if (isWeb) {
      //@ts-ignore
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg';

      input.onchange = e => {
        // @ts-ignore
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = event => {
          const data = event.target.result;
          //@ts-ignore
          setProfilePicture(data.split(',')[1]);
        };
      };

      input.click();
    } else {
      const ImagePicker = require('react-native-image-crop-picker').default;
      ImagePicker.openPicker(IMAGE_OPTIONS).then(image => {
        //@ts-ignore
        setProfilePicture(image.data);
      }).then((e)=> handlePermissionError(e, 'gallery'));
    }
  };

  const showCameraPicker = () => {
    const ImagePicker = require('react-native-image-crop-picker').default;
    ImagePicker.openCamera({...IMAGE_OPTIONS, cropping: true}).then(image => {
      const orientation = image.exif.Orientation;
      let rotationAngle = 0;

      if (orientation != 1) {
        switch (orientation) {
          case '3':
            rotationAngle = 180;
            break;
          case '6':
            rotationAngle = 90;
            break;
          case '8':
            rotationAngle = 270;
            break;
          default:
            break;
        }

        Sentry.captureMessage('rotationAngle' + rotationAngle);

        const ImageResizer =
          require('@bam.tech/react-native-image-resizer').default;

        ImageResizer.createResizedImage(
          `data:image/jpeg;base64,${image.data}`,
          IMAGE_OPTIONS.maxWidth,
          IMAGE_OPTIONS.maxHeight,
          'JPEG',
          80,
          rotationAngle,
        )
          .then(response => {
            const RNFS = require('react-native-fs');
            RNFS.readFile(response.uri, 'base64')
              .then(base64Img => {
                setProfilePicture(base64Img);
              })
              .catch(err => {
                console.log('err', err);
              });
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        setProfilePicture(image.data);
      }
    }).catch((e)=> handlePermissionError(e, 'camera'));
  };

  const checkPhotoValidity = async () => {
    setLoading(true);
    try {
      const isPhotoValid = await checkProfileImageValidity(profilePicture);

      setPhotoValid(isPhotoValid);
      if (isPhotoValid) {
        handleImageSubmit();
      } else {
        setDialogOpen(true);
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeArea,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={7} />
        <ScrollView style={styles.scrollView}>
          <View style={styles.container}>
            <Heading size="lg">Profile Picture</Heading>
            <Text style={styles.text}>Please load a picture of you.</Text>
            <Text style={styles.text}>
              This needs to be a real picture of you facial recognition will
              compare it to the drivers license photo and your profile if it
              does not match you profile can be rejected.
            </Text>
            <Center style={styles.center}>
              {profilePicture ? (
                <Image
                  source={{
                    uri: `data:image/jpeg;base64,${profilePicture}`,
                  }}
                  style={globalStyles.profileImage}
                />
              ) : (
                <Image
                  source={require('../../../images/default-user-avatar.png')}
                  style={globalStyles.profileImage}
                  resizeMode="contain"
                />
              )}
            </Center>
            {isWeb ? (
              <WebcamCapture
                onCapture={imgData => setProfilePicture(imgData)}
              />
            ) : (
              <Button
                style={styles.button}
                variant="ghost"
                size="lg"
                py="4"
                onPress={() => showCameraPicker()}>
                Take picture
              </Button>
            )}
            <Button
              style={styles.button}
              variant="ghost"
              size="lg"
              py="4"
              onPress={() => showGalleryPicker()}>
              {isWeb ? 'Select file' : 'Choose from gallery'}
            </Button>
            <Text style={styles.warningText}>
              This profile will not be approved by management if the photo is
              not provided.
            </Text>
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button
            onPress={() =>
              !profilePicture ? setDialogOpen(true) : checkPhotoValidity()
            }
            isLoading={loading}
            isLoadingText="Checking ..."
            style={styles.nextButton}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onNoPressed={closeDialog}
        onYesPressed={() => navigation.navigate('Password')}
        title={!!profilePicture ? 'Invalid picture' : 'No picture provided'}
        description={
          !profilePicture
            ? 'This profile will not be approved by management if the photo is not provided.'
            : 'This picture is not appropriate for recognizing you. Please provide a real picture of you.'
        }
        positiveLabel="CONTINUE "
        negativeLabel="LOAD PHOTO          "
      />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    marginVertical: 16,
  },
  scrollView: {
    marginBottom: 16,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 16,
    fontSize: 18,
  },
  center: {
    marginTop: 24,
  },
  button: {
    flex: 1,
    marginTop: 24,
  },
  warningText: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  hStack: {
    marginHorizontal: 8,
    marginBottom: 36
  },
  nextButton: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default PhotoCapture;
