import React, { useState } from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {RegistrationData} from '../types';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import TextInput from '../../../components/TextInput';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { checkPhone } from '../api';
import { RootStore } from '../../../redux/store';

const phoneValidationSchema = yup.object().shape({
  phone1: yup
    .string()
    .required('Phone is required')
    .min(10, 'Invalid phone number'),
});

const Phone = ({navigation}) => {
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();

  const {phone1} = useSelector((state: RootStore) => state.register);
  const {control, handleSubmit, reset} = useForm({
    defaultValues: {
      phone1,
    },
    resolver: yupResolver(phoneValidationSchema),
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog =()=> setDialogOpen(false);

  const [loading, setLoading] = useState(false);

  const handlePhoneSubmit = async(values: {phone1: string}) => {
    setLoading(true);
    const phoneExists = await checkPhone(values.phone1);
    setLoading(false);

    if(phoneExists){
      setDialogOpen(true);
      return;
    }
    const registrationData: RegistrationData = {
      phone1: values.phone1,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('User Credentials');
  };

  const continueWithDuplicatePhone = () => {
    const registrationData: RegistrationData = {
      phone1: control._formValues.phone1,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('User Credentials');
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeArea,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={2} />
        <ScrollView keyboardShouldPersistTaps="handled">
          <View style={styles.container}>
            <Heading size="lg">Cell Phone</Heading>
            <Text style={styles.text}>
              Type your valid cell phone number.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="phone1"
              placeholder="(XXX) XXX-XXXX"
              label="Phone"
              keyboardType="number-pad"
            />
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button
            onPress={ handleSubmit(handlePhoneSubmit)}
            style={styles.button}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onNoPressed={closeDialog}
        onYesPressed={continueWithDuplicatePhone}
        description={'Phone number already in use'}
        negativeLabel="EDIT PHONE NUMBER "
        positiveLabel="CONTINUE "
      />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    marginVertical: 16,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 16,
    fontSize: 18,
  },
  hStack: {
    margin: 16,
    marginBottom: 36
  },
  button: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default Phone;
