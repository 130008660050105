import React, {useRef, useState} from 'react';
import {
  Keyboard,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Icon from 'react-native-vector-icons/Feather';
import {
  AsterixDialog,
  RegisterActionBar,
  RegisterPrevButton,
} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {RegistrationData} from '../types';
import {RootStore} from '../../../redux/store';
import {residentApi} from '../../../api/resident';
import {showErrorMessage} from '../../../service/flashMessage';
import Checkbox from '../../../components/Checkbox';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import {SafeAreaView} from 'react-native';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {E_NAVIGATION_DESTINATIONS, E_STORAGE_KEYS} from '../../../@types';
import {getUniqueId} from 'react-native-device-info';
import {
  checkUnitPeopleCode,
  linkPersonToUnitLink,
} from '../../People/peopleReducer';

const passwordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const Password = ({navigation}) => {
  const [loading, setLoading] = useState(false);
  const [passwordHidden, setpasswordHidden] = useState(true);
  const [doNotBuildFaceTemplate, setDoNotBuildFaceTemplate] = useState(false);
  const dispatch = useDispatch();
  const registerValues = useSelector((state: RootStore) => state?.register);
  const scrollViewRef = useRef<ScrollView>(null);

  const {control, handleSubmit} = useForm({
    defaultValues: {
      password: registerValues.password,
      confirmPassword: registerValues.password,
    },
    resolver: yupResolver(passwordValidationSchema),
  });

  const {width} = useWindowDimensions();

  const registerUser = async (password: string) => {
    const deviceId = await getUniqueId();

    const allRegisterValues = {
      ...registerValues,
      password,
      doNotBuildFaceTemplate,
      listValidDeviceIds: [deviceId],
    };

    setLoading(true);

    try {
      const res = await residentApi().post(
        '/people/register',
        allRegisterValues,
      );
      const result = res.data;
      if (result.errors) {
        showErrorMessage(result?.errors[0]);
      } else {
        const data = result.data;
        if (data?.token) {
          const {token, refreshToken} = data;
          await AsyncStorage.setItem(
            E_STORAGE_KEYS.AUTH_TOKEN,
            JSON.stringify({
              token,
              refreshToken,
            }),
          );
        }

        if (!!registerValues.linkCode) {
          await linkPersonToUnit(data?.id);
          return;
        } else {
          navigation.navigate(
            E_NAVIGATION_DESTINATIONS.CONFIRIRMATION_CODES_ENTRY,
            {
              personId: data?.id,
              errors: data?.errors,
            },
          );
        }
      }
    } catch (error) {
      const message = error.response.data.errors[0];
      showErrorMessage(message);
    } finally {
      setLoading(false);
    }
  };

  const linkPersonToUnit = async personId => {
    try {
      const unitPeoplelink = await checkUnitPeopleCode(registerValues.linkCode);
      const result = await linkPersonToUnitLink({
        unitPeopleLinkId: unitPeoplelink?.id,
      });

      if (result.errors) {
        showErrorMessage(result?.errors[0]);
      } else {
        navigation.navigate(
          E_NAVIGATION_DESTINATIONS.CONFIRIRMATION_CODES_ENTRY,
          {personId},
        );
      }
    } catch (error) {
      showErrorMessage(error.message);
      navigation.navigate(
        E_NAVIGATION_DESTINATIONS.CONFIRIRMATION_CODES_ENTRY,
        {personId},
      );
    }
  };

  const handlePasswordSubmit = async (values: {
    password: string;
  }) => {
    Keyboard.dismiss();

    const registrationData: RegistrationData = {
      password: values.password,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));

    await registerUser(values.password);
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeAreaView,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={7} />
        <ScrollView
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled"
          ref={scrollViewRef}
          showsVerticalScrollIndicator>
          <View style={styles.container}>
            <Heading size="lg">Password</Heading>
            <Text style={styles.text}>
              Type a strong and memorable password to secure your account.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="password"
              placeholder=""
              label="Password *"
              autoCapitalize="none"
              secureTextEntry={passwordHidden}
              blurOnSubmit={false}
              textContentType="oneTimeCode"
              InputRightElement={
                <TouchableOpacity
                  style={styles.iconButton}
                  onPress={() => setpasswordHidden(!passwordHidden)}>
                  <Icon
                    name={passwordHidden ? 'eye-off' : 'eye'}
                    size={24}
                    color="gray"
                  />
                </TouchableOpacity>
              }
            />
            <View style={styles.passwordRequirements}>
              <Text style={styles.requirementText}>
                * Passwords must have at least one non alphanumeric character.
              </Text>
              <Text style={styles.requirementText}>
                * Passwords must have at least one digit.
              </Text>
              <Text style={styles.requirementText}>
                * Passwords must have at least one uppercase.
              </Text>
            </View>
            <TextInput
              //@ts-ignore
              control={control}
              name="confirmPassword"
              placeholder=""
              label="Confirm password *"
              autoCapitalize="none"
              secureTextEntry={passwordHidden}
              blurOnSubmit={false}
              textContentType="oneTimeCode"
              InputRightElement={
                <TouchableOpacity
                  style={styles.iconButton}
                  onPress={() => setpasswordHidden(!passwordHidden)}>
                  <Icon
                    name={passwordHidden ? 'eye-off' : 'eye'}
                    size={24}
                    color="gray"
                  />
                </TouchableOpacity>
              }
            />
            <View style={styles.checkboxContainer}>
              <Checkbox
                style={styles.checkbox}
                value={doNotBuildFaceTemplate ? 'true' : 'false'}
                isChecked={doNotBuildFaceTemplate}
                onChange={value => setDoNotBuildFaceTemplate(value)}
                aria-label="Do not build facetemplate"
              />
              <Text style={styles.checkBoxLabel}>
                Opt out of facial recognition
              </Text>
            </View>
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button
            onPress={handleSubmit(handlePasswordSubmit)}
            isLoading={loading}
            isLoadingText={'Submitting ...'}
            style={styles.button}>
            {'Next'}
          </Button>
        </HStack>
      </SafeAreaView>
      <AsterixDialog
        message={"We're saving your information."}
        isSwitching={loading}
      />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    marginVertical: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 32,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 32,
    fontSize: 18,
  },
  iconButton: {
    paddingHorizontal: 8,
  },
  passwordRequirements: {
    marginTop: Platform.OS === 'web' ? 0 : -10,
  },
  requirementText: {
    fontSize: 12,
  },
  checkboxContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: -16,
  },
  checkbox: {
    marginTop: 8,
    marginRight: 4,
  },
  checkBoxLabel: {
    fontSize: 14,
    marginTop: 8
  },
  hStack: {
    margin: 16,
    marginBottom: 36
  },
  button: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default Password;
