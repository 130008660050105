import React, {useRef, useState} from 'react';
import {
  ScrollView,
  View,
  useWindowDimensions,
  StyleSheet,
  SafeAreaView,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import {
  RegisterActionBar,
  RegisterPrevButton,
} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {RegistrationData} from '../types';
import {RootStore} from '../../../redux/store';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import { checkUser } from '../api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { E_NAVIGATION_DESTINATIONS } from '../../../@types';

const usernameValidationSchema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required')
    .min(4, 'Username can only have a minimum length of 4')
    .max(30, 'Username can have a maximum length of 30'),
});

const UserCredentials = ({navigation}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const registerValues = useSelector((state: RootStore) => state?.register);

  const scrollViewRef = useRef<ScrollView>(null);

  const {control, handleSubmit} = useForm({
    defaultValues: {
      username: registerValues.emailExists ? registerValues.username : registerValues.email,
    },
    resolver: yupResolver(usernameValidationSchema),
  });

  const {width} = useWindowDimensions();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog =()=> setDialogOpen(false);

  const handleUsernameSubmit = async (values: {
    username: string;
  }) => {
    setLoading(true);
    const usernameExists = await checkUser(values.username);
    setLoading(false);

    if(usernameExists){
      setDialogOpen(true);
      return;
    }

    const registrationData: RegistrationData = {
      username: values.username,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));

      navigation.navigate('Invite Code');
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeAreaView,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={3} />
        <ScrollView
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled"
          ref={scrollViewRef}
          showsVerticalScrollIndicator>
          <View style={styles.view}>
            <Heading size="lg">Username</Heading>
            <Text style={styles.text}>
             { `Type a memorable username to secure your account.${ registerValues.emailExists ? 'We could not prefill your email address because it is already in use.' :'We recommend your email address.'}`}
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="username"
              placeholder="e.g johndoe"
              label="Username *"
              autoCapitalize="none"
            />
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button
            onPress={handleSubmit(handleUsernameSubmit)}
            isLoading={loading}
            isLoadingText={'Checking ...'}
            style={styles.button}>
            {'Next'}
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        description="Username already exist, please try a different one."
        positiveLabel='OK '
        negativeLabel=''
        onYesPressed={() => {}}
        onNoPressed={() => {}}
      />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    marginVertical: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 32,
  },
  view: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 32,
    fontSize: 18,
  },
  hStack: {
    margin: 16,
    marginBottom: 36
  },
  button: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default UserCredentials;
